<template>
  <v-navigation-drawer app clipped style="background-color: #404040;" width="280" :value="getDraw" class="boxShawdom"
  >
    <v-overlay :value="getOver"></v-overlay>
    <v-list id="dash">
      <router-link :to="`/departamentos`" class="link">
        <v-list-group
            class="primary--text"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                  class="secondary--text"
                  style="font-weight: 500;padding: 20px"
              >Departamentos
              </v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-list-group>
      </router-link>
    </v-list>
    <v-list id="dash">
      <div style="padding-right: 10px; padding-left: 10px">
        <v-divider></v-divider>
      </div>
      <router-link :to="`/usuarios`" class="link">
        <v-list-group
            class="primary--text"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                  class="secondary--text"
                  style="font-weight: 500;padding: 20px"
              >Usuarios
              </v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-list-group>
      </router-link>
    </v-list>

    <v-list>
      <div style="padding-right: 10px; padding-left: 10px">
        <v-divider></v-divider>
      </div>
      <v-list-group
          class="primary--text"

      >
        <template v-slot:activator>

          <v-list-item-content>
            <v-list-item-title
                class="secondary--text"
                style="font-weight: 500; padding: 20px"
            >Licitación
            </v-list-item-title
            >
          </v-list-item-content>
        </template>
        <router-link to="/licitaciones" class="link">

          <v-list-item link class="" style="padding: 20px">
            <v-list-item-title>Lista</v-list-item-title>
          </v-list-item>
          <div style="padding-right: 10px; padding-left: 10px">
            <v-divider></v-divider>
          </div>
        </router-link>

      </v-list-group>
    </v-list>
    <div style="padding-right: 10px; padding-left: 10px">
      <v-divider></v-divider>
    </div>
    <v-list>
      <v-list-group
          class="primary--text"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
                class="secondary--text"
                style="font-weight: 500;padding: 20px"
            >Ajustes
            </v-list-item-title
            >
          </v-list-item-content>
        </template>
        <router-link to="/layouts_lista" class="link">
          <v-list-item link style="padding: 20px">
            <v-list-item-title>Layouts</v-list-item-title>
          </v-list-item>
        </router-link>

      </v-list-group>
    </v-list>
    <div style="padding-right: 10px; padding-left: 10px">
      <v-divider></v-divider>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SideBar",
  computed: {
    ...mapGetters(['getDraw', 'getOver']),
    ...mapGetters('general', ['getMenu', 'getSubMenu']),
  }
};
</script>

<style>
.link {
  text-decoration: none !important;
}

.v-application .secondary--text {
  color: white !important;
  text-align: center;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: white !important;
  text-align: center;
}

.v-list-group__items {
  background: #222222 !important;
}

.v-list-item__icon {
  color: white !important;
}

.v-list-group--active {
  background-color: #01AB9C;
}

.v-divider {
  background-color: white !important;
}

.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

</style>
