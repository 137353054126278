<template>
  <v-app id="body">
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <v-main class="fadeIn" app>

      <slot/>
    </v-main>
  </v-app>
</template>

<script>
import ButtonDark from "../components/ButtonDark";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DashboardLayout",
  components: {
    Navbar,
    Sidebar,
    ButtonDark
  },
  computed: {
    ...mapGetters(['getOver']),

  },
  methods: {
    ...mapActions(['setOver'])
  },
  created() {
  },

}
</script>
<style lang="scss">


</style>
