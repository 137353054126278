<template>
  <div class="position-button">
    <span v-if="dark">
      <v-icon
          small
          class="mr-2 btn-change-dark"
          @click="btnDark()"
      >
          fas fa-sun

      </v-icon>
    </span>
    <span v-else>
      <v-icon
          small
          class="mr-2 btn-change-dark"
          @click="btnDark()"
      >
             fas fa-moon
      </v-icon>
    </span>
  </div>


</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ButtonDark",
  data(){
    return{
      dark:false

    }
  },
  async created() {
    await this.verifyDark()

  },
  methods:{
    ...mapActions("general", ['setThemeColor']),
   async verifyDark(){
      const theme = localStorage.getItem("dark_theme");
      if (theme === 'true') {
        this.dark = true
      } else {

        this.dark = false
      }
    },
    async btnDark(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      await this.setThemeColor(this.$vuetify.theme.dark)
      await this.verifyDark()


    }
  }
}
</script>

<style lang="scss">


</style>
