<template>
  <div>
    <v-app-bar app clipped-left    class="" style="background: white;">
      <v-overlay :value="getOver"></v-overlay>
      <v-app-bar-nav-icon @click="setDraw"></v-app-bar-nav-icon>
      <img src="../assets/images/logo.png" style="width: 143px; height: 41px" alt="">
      <v-spacer></v-spacer>
      <div style="margin-top: 10px">
        <p class="mt-1" style="font-size: 12px; margin-right: 20px;  text-align: center">
          {{getUser.nombreCompleto}}
        </p>
      </div>
      <img src="../assets/images/line.png" alt="">
      <v-btn icon>
        <v-icon class="logout-color" @click="logout">mdi-export</v-icon>
      </v-btn>
    </v-app-bar>

  </div>


</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Navbar",
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters(['getOver'])
  },
  methods: {
    ...mapActions(['setDraw',]),
    ...mapActions('auth', ['logout',]),
  },
  created() {
  }
};
</script>

<style>
.logout-color {
  color: red !important;
}
</style>
